import {
  Component,
  ComponentRef,
  EnvironmentInjector,
  inject,
  OnInit,
  signal,
  Type,
  ViewContainerRef
} from '@angular/core';
import {ShowComponentService} from "./shared/services/show-component.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  ref = inject(ViewContainerRef);
  envInjector = inject(EnvironmentInjector);
  componentToShow: ComponentRef<any> | undefined;

  constructor(private showComponentService: ShowComponentService) {}

  ngOnInit(): void {
    this.subscribeToShowComponentChanges();
  }

  /**
   * Subscribe to changes in the component to render.
   * This is mostly used to render dialogs at the root level.
   * Use the showComponentService to change the component to render.
   */
  subscribeToShowComponentChanges() {
    const componentChangesObservable = this.showComponentService.getComponentChanges();
    componentChangesObservable.subscribe((component) => {
      // clear previous component to render
      if (this.componentToShow) {
        this.componentToShow.destroy();
      }

      this.componentToShow = this.ref.createComponent(component.component, {
        environmentInjector: this.envInjector,
      });
      // add all the inputs
      for (const [key, value] of Object.entries(component.inputs)) {
        this.componentToShow.setInput(key, value)
      }
    })
  }
}
