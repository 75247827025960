import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TooltipComponent} from "./components/tooltip/tooltip.component";
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tab/tab.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import {RouterLink, RouterOutlet} from "@angular/router";
import {Notfound404Component} from "./components/404/404.component";

@NgModule({
  declarations: [
    TooltipComponent,
    LoadingSpinnerComponent,
    TabsComponent,
    TabComponent,
    NavbarComponent,
    Notfound404Component
  ],
  exports: [
    TooltipComponent,
    LoadingSpinnerComponent,
    TabsComponent,
    TabComponent,
  ],
    imports: [
        CommonModule,
        RouterOutlet,
        RouterLink
    ]
})
export class SharedModule { }
