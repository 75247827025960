import {Subject} from "rxjs";
import {Injectable, Type} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ShowComponentService {
  showComponentSubject = new Subject<{
    component: Type<any>,
    inputs: Record<string, unknown>
  }>();
  showComponent(component: Type<any>, inputs: Record<string, unknown>): void {
    this.showComponentSubject.next({
      component,
      inputs
    });
  }

  getComponentChanges() {
    return this.showComponentSubject.asObservable();
  }
}
