import {
  createTRPCProxyClient,
  createWSClient,
  httpBatchLink,
  wsLink,
  splitLink,
  loggerLink,
  TRPCLink, TRPCClientError,
} from "@trpc/client";
import {AppRouter} from "../../../../backend/src/router/trpc.router";
import {environment} from "../../environments/environment";
import {AuthService} from "../authentication/services/auth.service";

// @ts-ignore
export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    loggerLink({
      enabled: () => true,
      logger: (err) => {
        // check for 401
        if(err.type === 'subscription') return; // ignored for subscription based errors
        const isUnAuthorizedOrExpiredAuthCode =
          err.direction === 'down' && err.result instanceof TRPCClientError && err.result.message === 'UNAUTHORIZED';
        if (isUnAuthorizedOrExpiredAuthCode) {
          window.location.href = '/auth/login?nextUrl=' + window.location.pathname;
        }
      }
    }),
    splitLink({
      // use the WebSocket link only for subscriptions
      condition: (op) => op.type === 'subscription',
        true: wsLink({
            client: createWSClient({
              url: environment.tRPCWebSocketURL,
            }),
        }),
        false: httpBatchLink({
          url: environment.tRPCBackendURL,
          headers() {
            const jwtAuthFormat = `Bearer ${AuthService.getJWTToken()}`
            return {
              Authorization: jwtAuthFormat,
            };
          },
        }),
    }),
  ],
});
