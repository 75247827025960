import {NgModule} from "@angular/core";
import { HomeComponent } from './components/home/home.component';
import {RouterLink} from "@angular/router";
import {DocumentationModule} from "../documentation/documentation.module";

@NgModule({
  declarations: [
    HomeComponent // landing page
  ],
    imports: [
        RouterLink,
        DocumentationModule
    ],
  providers: [],
  exports: [HomeComponent]
})
export class HomeModule {}
