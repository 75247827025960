import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/components/home/home.component";
import {Notfound404Component} from "./shared/components/404/404.component";

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'repositories', loadChildren: () => import('./repositories/repositories.module').then(m => m.RepositoriesModule) },
  { path: 'docs', loadChildren: () => import('./documentation/documentation.module').then(m => m.DocumentationModule) },
  { path: '', component: HomeComponent },
  { path: '**', component: Notfound404Component, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
