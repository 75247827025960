<section class="container mx-auto max-w-3xl p-4 text-text">
  <header>
    <h1 class="header">Resource limitations of containers</h1>
    <app-contact-us align="left"></app-contact-us>
  </header>
  <article class="mt-2">
    <p class="font-bold">Container limit</p>
    <p>Each container can use a maximum of the following resources:</p>
    <p>Storage: {{ deploymentConfig.containerResourcesLimit.storage }}</p>
    <p>Memory: {{ deploymentConfig.containerResourcesLimit.memory }}</p>
    <p class="italic">If the container exceeds the memory limit, it will be terminated.</p>
    <p class="font-bold mt-4">Project limit</p>
    <p>
      Each project can have maximum of {{ deploymentConfig.maxContainers }} containers.
    </p>
    <p>
      Each image size should be less than {{ deploymentConfig.maxContainerImageSize }}.
    </p>
  </article>
  <app-contact-us></app-contact-us>
</section>
