import {trpcClient} from "../../trpc/trpc.client";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {map, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private router: Router,
  ) {}
  exchangeGithubCodeForJWTToken(githubAccessToken): Observable<any> {
    return fromPromise<string>(trpcClient.authentication.exchangeGithubTokenForJWTToken.mutate({
      githubAccessToken: githubAccessToken,
    })).pipe(
      map((jwtToken) => {
        // save the jwt
        this.saveJWTToken(jwtToken);
      }
    ));
  }

  logout() {
    sessionStorage.removeItem('jwtToken');
    this.prepareForLogout();
    this.router.navigate(['auth', 'login']);
  }

  prepareForLogout() {
    sessionStorage.setItem('logout', 'true');
  }

  wasLogout() {
    return sessionStorage.getItem('logout') === 'true';
  }

  removeLogoutFlag() {
    sessionStorage.removeItem('logout');
  }

  private setCookie(name, value, domain, path = '/') {
    const shouldHaveSecure = window.location.protocol === 'https:';
    document.cookie = name + '=' + encodeURIComponent(value) + '; path=' + path + '; domain=' + domain + '; SameSite=None; Secure=' + shouldHaveSecure + ';';
  }

  private saveJWTToken(jwtToken) {
    // add the JWT token to the cookie so that the websocket can use it
    // we do this when the websocket is created to make sure the latest token is used
    const currentDomain = window.location.hostname;
    sessionStorage.setItem('jwtToken', jwtToken);
    this.setCookie('Authorization', `Bearer ${jwtToken}`, currentDomain);
  }

  static getJWTToken() {
    return sessionStorage.getItem('jwtToken');
  }
}
