/**
 * Configuration settings that are the same across all environments
 */
export const globalConfig = {
  /**
   * The length of the API key that is generated for each project
   */
  apiKeyLength: 32,
  /**
   * Github actions secret name for the compose deploy api key
   */
  githubComposeDeployEnvironmentSecretName: 'COMPOSE_DEPLOY_API_KEY',

  githubComposeDeployEnvironmentFileName:
    '.github/workflows/compose-deploy.yml',

  /**
   * Maximum amount of projects per user
   */
  maximumAmountOfProjectPerUser: 5,

  /**
   * Maximum amount of settings per project, this is to prevent abuse
   */
  maximumAmountOfSettingsPerProject: 100,

  rateLimiter: {
    /**
     * Maximum amount of requests per minute
     */
    maximumAmountOfRequestPerPeriod: 80,

    /**
     * Maximum amount of requests per second
     */
    periodDuration: 30 * 1000, // 30 seconds
  },

  kubernetes: {
    /**
     * Maximum amount of logs to get from the kubernetes API
     */
    maximumAmountOfLogsToGet: 5000,
    /**
     * When you want to expose a port we will generate a random port in this range that kubernetes will use as external port
     */
    portRange: {
      // this is the default port range that kubernetes uses
      // in production this should probably be expanded to the full range
      // https://kubernetes.io/docs/concepts/services-networking/service/#choosing-your-own-ip-address
      start: 30000,
      end: 32767,
    },

    /**
     * The port that the ingress server will listen on
     */
    webPort: 443,

    /**
     * Maximum amount of storage per container
     */
    maximumStoragePerContainer: '10Gi',

    /**
     * Minimum amount of memory per container
     */
    minimumMemoryPerContainer: '6Mi',

    /**
     * Maximum amount of memory per container
     */
    maximumMemoryPerContainer: '1Gi',

    /**
     * Maximum amount of containers per project
     */
    maximumAmountOfContainerPerProject: 8,

    /**
     * Maximum amount of persistent volume claims per project
     */
    maximumAmountOfPersistentVolumeClaimsPerProject: 8 * 2, // 2 volumes per container

    /**
     * How long the container can be in the terminating state before shutting down
     */
    containerTtlAfterFinished: 60, // in seconds
  },
} as const;
