import { Component } from '@angular/core';
import { globalConfig } from '../../../../../../backend/global-config';

@Component({
  selector: 'app-resource-limitations',
  templateUrl: './resource-limitations.component.html',
  styleUrls: ['./resource-limitations.component.scss']
})
export class ResourceLimitationsComponent {
  deploymentConfig = {
    maxContainers: globalConfig.kubernetes.maximumAmountOfContainerPerProject,
    maxContainerImageSize: '1.5GB',
    containerResourcesLimit: {
      memory: globalConfig.kubernetes.maximumMemoryPerContainer,
      storage: globalConfig.kubernetes.maximumStoragePerContainer,
    },
  } as const
}
