import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {documentationRoutes} from "./documentation.routes";
import { DeploymentLimitationsComponent } from './components/deployment-limitations/deployment-limitations.component';
import { DocsHomeComponent } from './components/docs-home/docs-home.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { LegalComponent } from './components/legal/legal.component';
import { ResourceLimitationsComponent } from './components/resource-limitations/resource-limitations.component';
import { HowDeploymentsWorkComponent } from './components/how-deployments-work/how-deployments-work.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(documentationRoutes)
  ],
  exports: [
    ContactUsComponent
  ],
  declarations: [
    DeploymentLimitationsComponent,
    DocsHomeComponent,
    ContactUsComponent,
    LegalComponent,
    ResourceLimitationsComponent,
    HowDeploymentsWorkComponent
  ]
})
export class DocumentationModule {}
