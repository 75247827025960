const clusterHost = 'ployer.app';
export const environment = {
  tRPCBackendURL: 'https://api.ployer.app/trpc',
  tRPCWebSocketURL: 'wss://api-ws.ployer.app',
  production: true,
  kubernetesCluster: {
    webHost: clusterHost,
    containerHost: `containers.${clusterHost}`,
  },
  auth: {
    issuer: 'https://github.com/login/oauth/authorize',
    scopes: 'read:org%20repo%20public_repo%20workflow',
    redirectUri: window.location.origin + '/auth/login', // so for development, this is http://localhost:4200/index.html
    clientId: '6e99486449fcd97167d6',
    showDebugInformation: true,
  }
};
