import {Component, ElementRef, Renderer2} from '@angular/core';
import {AuthService} from "../../../authentication/services/auth.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private authService: AuthService
  ) { }
  toggleMobileMenu() {
    const mobileMenu = this.el.nativeElement.querySelector('.mobile-menu');

    if (mobileMenu.classList.contains('hidden')) {
      this.renderer.removeClass(mobileMenu, 'hidden');
    } else {
      this.renderer.addClass(mobileMenu, 'hidden');
    }
  }

  logout() {
    this.authService.logout();
  }
}
