<section class="container mx-auto max-w-3xl p-4 text-text">
  <header>
    <h1 class="header">How deployments work</h1>
    <app-contact-us align="left"></app-contact-us>
  </header>
  <article class="mt-2">
    <p>
      The whole deployment process is about using you're provided Docker compose file to setup your application.
    </p>
    <br>
    <p>
      The deployment process is done in 3 steps:
    </p>
    <dl>
      <dt class="font-bold mt-4">Step 1: Create a project</dt>
      <dd>
        <p>
          The first step is to create a project in our dashboard. See the <a href="/repositories">projects</a> page for more information (you may be prompted to login with Github).
          In the project, you connect you're Github repository, specify the branch you want to deploy and the Docker compose file you want to use.
          <br>
          <i>Each time you push a commit to the specified branch, we will automatically build and deploy the code.</i>
        </p>
      </dd>
      <dt class="font-bold mt-4">Step 2: Building you're code</dt>
      <dd>
        <p>
          Once you have connected you're repository, the next step is to build the code.
          This is done automatically by a Github action we have created for you when you connect you're repository.
          We will build all the services you have specified in the Docker compose file.
          Then push all those images to our Docker registry so we can use them in the deployment.
        </p>
      </dd>
      <dt class="font-bold mt-4">Step 3: Configure you're deployment</dt>
      <dd>
        <p>
          Configure your deployment settings as per your requirements. Things like environment variables and public ports.
        </p>
      </dd>
      <dt class="font-bold mt-4">Step 4: Monitor</dt>
      <dd>
        <p>
          Once you have deployed your application, you can monitor the status of the deployment.
          You can see the logs of the deployment and the status of each service in our dashboard.
        </p>
      </dd>
    </dl>
  </article>
</section>
