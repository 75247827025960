<section class="container mx-auto max-w-3xl p-4 text-text">
  <header>
    <h1 class="header">Limitations of Ployer deployments</h1>
    <app-contact-us align="left"></app-contact-us>
  </header>
  <article class="mt-2">
    <header>
      <h2 class="smaller-header">Don't use locally mounted volumes</h2>
    </header>
    <p>
      For example volumes like this:
    </p>
    <pre class="mt-2 max-w-full whitespace-pre-wrap bg-background">
volumes:
  - ./data:/var/www/html/data
    </pre>
    <p>
      Will not work, because the data is not on the server, but only on you're local machine or git repository.
      If you want certain files to be in you're container please include them when building the image.
      For example add the <code>COPY data/ data/</code> in you're Dockerfile to copy the data folder to the image.
    </p>
  </article>
  <article class="mt-4">
    <header>
      <h2 class="smaller-header">Set the ports of the container</h2>
    </header>
    <p>
      If you want a port of container to be used by containers or made public by Ployer you need to set the ports in you're docker compose file.
      This works the same as you would do with docker compose.
      For example:
    </p>
    <pre class="mt-2 max-w-full whitespace-pre-wrap bg-background">
services:
  web:
    image: nginx:latest
    ports:
      - 80:80
    </pre>
    <p>
      This will make the port 80 of the container available to other containers and ployer.
      If you want to make this port public you need to add public ports in the "Public port" section of the project.
    </p>
  </article>
  <article class="mt-4">
    <header>
      <h2 class="smaller-header">env_file will be ignored during deployment</h2>
    </header>
    <p>
      If you use the <code>env_file</code> option in you're docker compose file, the variables will be ignored during deployment.
      This is because we don't have access to you're env_file during deployment and you should not store it in you're git repository.
      If you want to use environment variables in you're container please use the <code>environment</code> option in you're docker compose file or create them in the dashboard.
      For example:
      <pre>
  services:
      image: nginx:latest
      environment:
        - ENVIRONMENT_VARIABLE=VALUE
      </pre>
  </article>
  <article class="mt-4">
    <header>
      <h2 class="smaller-header">Project limit</h2>
    </header>
    <p>
      Each project can have maximum of {{ deploymentConfig.maxContainers }} containers.
    </p>
    <p>
      Each image size should be less than {{ deploymentConfig.maxContainerImageSize }}.
    </p>
  </article>
  <app-contact-us></app-contact-us>
</section>
