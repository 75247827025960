import {Routes} from "@angular/router";
import {NavbarComponent} from "../shared/components/navbar/navbar.component";
import {DocsHomeComponent} from "./components/docs-home/docs-home.component";
import {DeploymentLimitationsComponent} from "./components/deployment-limitations/deployment-limitations.component";
import {LegalComponent} from "./components/legal/legal.component";
import {ResourceLimitationsComponent} from "./components/resource-limitations/resource-limitations.component";
import {HowDeploymentsWorkComponent} from "./components/how-deployments-work/how-deployments-work.component";

export const documentationRoutes: Routes = [
  {
    // entry point path for routes
    path: '',
    component: NavbarComponent, // has a <router-outlet> in its template
    children: [
      {
        path: '',
        component: DocsHomeComponent,
        title: 'Ployer - Documentation',
      },
      {
        path: 'legal',
        component: LegalComponent,
        title: 'Ployer - Legal',
      },
      {
        path: 'how-to-deploy',
        component: HowDeploymentsWorkComponent,
        title: 'Ployer - How to deploy',
      },
      {
        path: 'deployment-limitations',
        component: DeploymentLimitationsComponent,
        title: 'Ployer - Deployment limitations',
      },
      {
        path: 'resource-limitations',
        component: ResourceLimitationsComponent,
        title: 'Ployer - Resource limitations',
      }
    ]
  }
]
