import { Component } from '@angular/core';
import { globalConfig } from '../../../../../../backend/global-config';

@Component({
  selector: 'app-deployment-limitations',
  templateUrl: './deployment-limitations.component.html',
  styleUrls: ['./deployment-limitations.component.scss']
})
export class DeploymentLimitationsComponent {
  deploymentConfig = {
    maxContainers: globalConfig.kubernetes.maximumAmountOfContainerPerProject,
    maxContainerImageSize: '1.5GB',
    containerResourcesLimit: {
      memory: globalConfig.kubernetes.maximumMemoryPerContainer,
      storage: globalConfig.kubernetes.maximumStoragePerContainer,
    },
  } as const
}
