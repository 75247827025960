import { Component } from '@angular/core';

@Component({
  selector: 'app-how-deployments-work',
  templateUrl: './how-deployments-work.component.html',
  styleUrls: ['./how-deployments-work.component.scss']
})
export class HowDeploymentsWorkComponent {

}
