<nav class="bg-secondary w-full">
  <div class="p-4 flex items-center justify-between">
    <!-- Logo -->
    <img src="assets/logo.png" alt="Logo" class="h-8">

    <!-- Mobile menu button -->
    <button (click)="toggleMobileMenu()" class="lg:hidden text-accent focus:outline-none">
      <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"></path>
      </svg>
    </button>

    <!-- desktop menu items -->
    <div class="hidden lg:flex space-x-4">
      <a routerLink="/repositories" class="btn-primary">Projects</a>
      <a routerLink="/docs/" class="btn-primary">Documentation</a>
      <a routerLink="/docs/legal/" class="btn-primary">Legal</a>
      <a href="https://forms.gle/fQuMobAzf5Tr2KhE8" target="_blank" rel="noopener noreferrer" class="btn-primary">Got feedback or issues?</a>
      <!-- Router link does not work for some reason-->
      <button (click)="logout()" class="btn-primary">Logout</button>
    </div>
  </div>
  <!-- Mobile menu items (hidden by default) -->
  <div class="lg:hidden mobile-menu hidden flex flex-col m-4">
    <a routerLink="/repositories" class="btn-primary mt-4">Projects</a>
    <a routerLink="/docs/" class="btn-primary mt-4">Documentation</a>
    <a routerLink="/docs/legal/" class="btn-primary mt-4">Legal</a>
    <a href="https://forms.gle/fQuMobAzf5Tr2KhE8" target="_blank" rel="noopener noreferrer" class="btn-primary mt-4">Got feedback or issues?</a>
    <!-- Router link does not work for some reason-->
    <button (click)="logout()" class="btn-primary my-4">Logout</button>
  </div>
</nav>
<router-outlet></router-outlet>
