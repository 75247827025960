<section class="text-center mt-4">
  <header>
    <h1 class="text-2xl md:text-3xl lg:text-4xl font-bold text-text">Legal document of Ployer</h1>
    <p class="text-text mt-4">
      Below the legal documents of Ployer are listed. Please read them carefully before using the service.
    </p>
  </header>
  <ol class="py-2 text-text">
    <li>
      <a href="/assets/legal/terms_of_service.pdf" class="small-link">Terms of service</a>
    </li>
    <li>
      <a href="/assets/legal/privacy_policy.pdf" class="small-link">Privacy policy</a>
    </li>
  </ol>
</section>
