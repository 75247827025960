<section class="container mx-auto max-w-3xl p-4 text-text">
  <header>
    <h1 class="header">Welcome to the Ployer documentation!</h1>
  </header>
  <p>Some useful links on problems you might face during deployment</p>
  <ul class="mt-4">
    <li>
      <a class="small-link" routerLink="/docs/how-to-deploy">How deployments work</a>
    </li>
    <li>
      <a class="small-link" routerLink="/docs/deployment-limitations">Deployment limitations</a>
    </li>
    <li>
      <a class="small-link" routerLink="/docs/resource-limitations">Resource limitations</a>
    </li>
  </ul>
  <br>
  <app-contact-us align="left"></app-contact-us>
</section>
