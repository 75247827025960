<div class="flex flex-col min-h-screen bg-background">
  <main class="flex-1">
    <section class="w-full pt-12 md:pt-32 lg:pt-48">
      <div class="px-4 md:px-6 space-y-10 xl:space-y-16">
        <div class="grid max-w-[1300px] mx-auto gap-4 px-4 sm:px-6 md:px-10 md:grid-cols-2 md:gap-16">
          <div>
            <h1 class="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem] text-text text-text">
              Deploy Docker Compose Files Effortlessly
            </h1>
            <p class="mt-4 text-xl text-accent">
              Ployer is a powerful tool that simplifies the deployment of Docker Compose files. Get your applications up and running in no time!
            </p>
            <div class="mt-6">
              <a href="https://waitforit.me/signup/d3f71945" class="inline-flex animate-pulse h-10 w-50 items-center justify-center rounded-md btn-primary !text-lg !font-bold !p-6">
                Join the waitlist
              </a>
              <p class="text-text italic">Want to learn more? Checkout our <a routerLink="/docs" class="small-link">documentation.</a></p>
            </div>
          </div>
          <div class="flex flex-col items-start space-y-4">
            <img src="/assets/images/landing/demo-project.png" alt="project demo" class="w-full aspect-[5/3] rounded-lg overflow-hidden object-cover" width="500" height="300">
          </div>
        </div>
      </div>
    </section>
    <section class="w-full py-12 md:py-24 lg:py-16">
      <div class="space-y-12 px-4 md:px-6">
        <div class="flex flex-col items-center justify-center space-y-4 text-center">
          <div class="space-y-2">
            <h2 class="text-3xl font-bold tracking-tighter sm:text-5xl text-text">
              Why use Ployer?
            </h2>
            <p class="max-w-[900px] text-accent md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed text-gray-400">
              Ployer offers a seamless experience for deploying Docker Compose files. Here's why developers love using our tool:
            </p>
          </div>
        </div>
        <div class="mx-auto grid items-start gap-8 sm:max-w-4xl sm:grid-cols-2 md:gap-12 lg:max-w-5xl lg:grid-cols-3">
          <div class="grid gap-1">
            <h3 class="text-lg font-bold text-text">
              Easy to Use
            </h3>
            <p class="text-sm text-accent">
              Simply connect you're Github repository and we'll build, push and deploy you're docker compose files.
            </p>
          </div>
          <div class="grid gap-1">
            <h3 class="text-lg font-bold text-text">
              Deploy anything
            </h3>
            <p class="text-base text-accent">
              Because we use Docker Compose files for deployment you can deploy anything that can be run in a container.
            </p>
          </div>
          <div class="grid gap-1">
            <h3 class="text-lg font-bold text-text">
              Reliable &amp; Secure
            </h3>
            <p class="text-base text-accent">
              We prioritize your data's security. Rest assured that your deployments are safe with us.
            </p>
          </div>
          <div class="grid gap-1">
            <h3 class="text-lg font-bold text-text">
              Automatic Builds and Deployments
            </h3>
            <p class="text-base text-accent">
              Ployer automatically builds your Docker images and pushes them to our registry for deployment.
              After that we'll deploy your containers and you're good to go!
            </p>
          </div>
          <div class="grid gap-1">
            <h3 class="text-lg font-bold text-text">
              Automatic HTTPS
            </h3>
            <p class="text-base text-accent">
              Ployer automatically generates SSL certificates for you're containers that are running a web server.
            </p>
          </div>
          <div class="grid gap-1">
            <h3 class="text-lg font-bold text-text">
              Storage Volumes
            </h3>
            <p class="text-base text-accent">
              Ployer allows you to create storage volumes for your containers.
              Simply create a volume in your docker compose file and we'll take care of the rest.
              No further configuration needed!
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="w-full flex flex-col items-center justify-center">
      <a href="https://waitforit.me/signup/d3f71945" class="btn-primary">
        Join the waitlist
      </a>
      <br>
      <p class="text-text italic">Want to learn more? Checkout our <a routerLink="/docs" class="small-link">documentation.</a></p>
    </div>
    <div class="w-full my-4 flex flex-col items-center justify-center">
      <h2 class="text-3xl my-4 font-bold tracking-tighter md:text-4xl/tight text-text">
        Demo
      </h2>
      <br>
      <iframe
        type="text/html"
        width="660"
        height="360"
        src="https://www.youtube.com/embed/CwUBrIY5hJs?autoplay=1&origin=https://ployer.app">
      </iframe>
    </div>
    <section class="w-full my-4 py-12 md:py-24 lg:py-16">
      <div class="grid items-center justify-center gap-4 px-4 text-center md:px-6">
        <div class="space-y-3">
          <h2 class="text-3xl font-bold tracking-tighter md:text-4xl/tight text-text">
            How It Works
          </h2>
          <p class="mx-auto max-w-[600px] text-accent md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
            Deploying your Docker Compose files with Ployer is as easy. Here's how:
          </p>
        </div>
        <div class="mx-auto w-full max-w-sm space-y-2">
          <div class="flex items-start gap-4">
            <div class="flex-1 space-y-1">
              <h3 class="text-lg font-semibold text-text">Connect you're Github repository</h3>
              <p class="text-base text-accent">We will take care of building all the images in you're docker compose file, pushing to a registry and deploying them on our infrastructure.</p>
            </div>
          </div>
          <div class="flex items-start gap-4">
            <div class="flex-1 space-y-1">
              <h3 class="text-lg font-semibold text-text">Configure you're deployment</h3>
              <p class="text-base text-accent">Configure your deployment settings as per your requirements. Things like environment variables and public ports.</p>
            </div>
          </div>
          <div class="flex items-start gap-4">
            <div class="flex-1 space-y-1">
              <h3 class="text-lg font-semibold text-text">Done!</h3>
              <p class="text-base text-accent">
                That's it! Now you can monitor and access you're application.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <footer>
    <app-contact-us></app-contact-us>
    <div class="mb-4"></div>
  </footer>
</div>
